export default class HomeIntroBlock {
  constructor(container, options) {
    const defaultOptions = {
    }

    this.options = Object.assign({}, defaultOptions, options)
    this.container = document.querySelector(container)

    this.setEvents()
  }

  setEvents() {
    this.backgroundImage()
    this.enterTopMask()
  }

  backgroundImage() {
    this.container.classList.add('enter')
  }

  enterTopMask() {
    if (this.container !== undefined) {
      this.container.classList.add('enter-top-mask')

      setTimeout(() => {
        this.exitTopMask()
      }, 400)
    }
  }

  exitTopMask() {
    if (this.container !== undefined) {
      this.container.classList.add('exit-top-mask')

      setTimeout(() => {
        this.exitBottomMask()
      }, 400)
    }
  }

  exitBottomMask() {
    if (this.container !== undefined) {
      this.container.classList.add('exit-bottom-mask')
    }
  }
}